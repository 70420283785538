import { priceFormatter } from '@/utils/';
import { displayPrice } from './helper';

const formatPrice = (currency, price) => priceFormatter(currency, displayPrice(price));

export const priceFn = ({ max_price, min_price, max_weight, min_weight }, currencySymbol) => {
  if (max_price === 0) {
    return `${formatPrice(currencySymbol, max_price / max_weight)} / KG`;
  } else if (min_price !== max_price || min_weight !== max_weight) {
    const [lowestPrice, highestPrice] = [min_price / min_weight, max_price / max_weight].sort(
      (a, b) => a - b
    );
    return `${formatPrice(currencySymbol, lowestPrice)} - ${formatPrice(currencySymbol, highestPrice)} / KG`;
  } else {
    return `${formatPrice(currencySymbol, min_price / min_weight)} / KG`;
  }
};

export const weightFn = ({ min_weight, max_weight }) => {
  let text = '';
  if (min_weight != max_weight) text = `${min_weight} - ${max_weight}`;
  else text = min_weight;
  return `${text} KG`;
};

export const priceDiscountFn = ({ max_price, min_price }, currencySymbol) => {
  if (max_price === 0) {
    return formatPrice(currencySymbol, max_price);
  } else if (min_price !== max_price) {
    return `${formatPrice(currencySymbol, min_price)} - ${formatPrice(currencySymbol, max_price)}`;
  } else {
    return formatPrice(currencySymbol, min_price);
  }
};

export const marketPriceFn = ({ min_market_price, max_market_price }, currencySymbol) => {
  if (min_market_price == max_market_price) {
    return formatPrice(currencySymbol, min_market_price);
  } else {
    return `${formatPrice(currencySymbol, min_market_price)} - ${formatPrice(
      currencySymbol,
      max_market_price
    )}`;
  }
};

export const discountFn = ({ min_market_price, max_market_price, min_price, max_price }) => {
  let minDiscount = 0;
  let maxDiscount = 0;
  if (min_market_price > min_price) {
    minDiscount = ((min_market_price - min_price) / min_market_price) * 100;
  }

  if (max_market_price > max_price) {
    maxDiscount = ((max_market_price - max_price) / max_market_price) * 100;
  }

  if (min_market_price == max_market_price) {
    return `${minDiscount.toFixed(2)}%`;
  }

  return `${minDiscount.toFixed(0)}% - ${maxDiscount.toFixed(0)}%`;
};

export const hasDiscountFn = ({ min_market_price, max_market_price, min_price, max_price }) => {
  let minDiscount = 0;
  let maxDiscount = 0;
  if (min_market_price > min_price) {
    minDiscount = ((min_market_price - min_price) / min_market_price) * 100;
  }

  if (max_market_price > max_price) {
    maxDiscount = ((max_market_price - max_price) / max_market_price) * 100;
  }

  return !!(minDiscount || maxDiscount);
};
